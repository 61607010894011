<template>
  <div class="wrap">
    <!-- <Navbar :leftArrow="false">
      <template #left>
        <div class="left_icon" @click="setting">
          <van-icon name="setting" size="18" color='var(--colors)' />
        </div>
      </template>
<template #right>
        <div class="right_icon" @click="service">
          <img style="height: calc(18rem / 16);" src="../../assets/tabbar/cs.svg" alt="">
        </div>
      </template>
</Navbar> -->
    <div class="userInfo_bar_wrap">
      <div class="left_icon" @click="setting">
        <van-icon name="setting" size="30" color='var(--light)' />
      </div>
      <div class="user_contentLeft">
        <div class="contentLeft_img">
          <Avatar size="70" :src="MemberInformation.userimg"
            :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
        </div>
        <div class="contentLeft_text">
          <div class="_id">ID:{{ MemberInformation.id }}</div>
          <div class="username">
            {{ MemberInformation.username ? MemberInformation.username
              : $t("newAdd.noLoginUserName") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- <div class="content_account">
        <div class="account_item">
          <div class="label">{{ $t('accountBalance') }}</div>
          <div class="value">{{ MemberInformation.money || 0 }}</div>
        </div>
        <div class="account_item">
          <div class="label">{{ $t('todayBet') }}</div>
          <div class="value">{{ MemberInformation.today_bet || 0 }}</div>
        </div>
        <div class="account_item">
          <div class="label">{{ $t('todayIncome') }}</div>
          <div class="value">{{ MemberInformation.today_win || 0 }}</div>
        </div>
      </div> -->
      <div class="content_btns">
        <div class="btns left">
          <div class="top">
            <div class="">{{ $t('totalAccount') }}:</div>
            <div class="">{{ MemberInformation.money }}</div>
          </div>
          <div class="btn_item withdraw" @click="toWithdraw">
            <img :src="require('../../assets/new/user/withdraws.png')" alt="">
            <div class="text">{{ $t("My.MemberInformation.Withdraw") }}</div>
          </div>
        </div>
        <div class="btns">
          <div class="top">
            <div class="">{{ $t('revenue') }}:&nbsp;{{ MemberInformation.betwin || 0 }}</div>
            <div class="">{{ $t('profitAndLoss') }}:&nbsp;{{ (MemberInformation.betwin || 0) -
              (MemberInformation.betmoney
                ||
                0)
              }}</div>
          </div>
          <div class="btn_item" @click="toRecharge">
            <img :src="require('../../assets/new/user/recharge.png')" alt="">
            <div class="text">{{ $t("My.MemberInformation.Recharge") }}</div>
          </div>
        </div>
      </div>
      <div class="content_list">
        <div class="group" v-for="(item, index) in gridItemArr" :key="index" @click="handleClick(item)">
          <div class="left_wrap">
            <img :src="item.icon" class="img" alt="">
            <span class="label">{{ $t(item.label) }}</span>
          </div>
        </div>
      </div>
      <div class="logout">
        <Btn block @click="logoutEvent">
          {{ MemberInformation.token ? $t("My.logout") : $t("newAdd.Login") }}
        </Btn>
      </div>
    </div>
    <setLanguage :isShow="false" ref="setLanRef" @submit="lanSubmit" />
  </div>
</template>
<script>
import { Toast, Dialog, Icon, Button as Btn, } from 'vant';
import { Avatar } from "@tt/components";
import Navbar from "@/components/JNav";
import setLanguage from "@/components/setLanguage.vue";
export default {
  components: {
    Avatar,
    [Dialog.name]: Dialog,
    Navbar,
    [Icon.name]: Icon,
    setLanguage,
    Btn
  },
  data() {
    return {
      MemberInformation: {},
      gridItemArr: [{
        label: `user.bettingRecord`,
        icon: require('../../assets/new/user/betting.png'),
        page: "bettingRecord",
      }, {
        label: "user.depositRecord",
        icon: require('../../assets/new/user/transfer.png'),
        page: "bankList",
        params: { type: 'depositRecord' }
      }, {
        label: "user.withdrawRecord",
        icon: require('../../assets/new/user/withdraw.png'),
        page: "bankList",
        params: { type: 'withdrawRecord' }
      }, {
        label: "user.fundingRecords",
        icon: require('../../assets/new/user/details.png'),
        page: "AccountData",
      }, {
        label: "user.information",
        icon: require('../../assets/new/user/agency.png'),
        page: "BankCardAdd",
      }, {
        label: "user.service",
        icon: require('../../assets/new/user/service.png'),
        page: "service",
      }, {
        label: "user.agentCenter",
        icon: require('../../assets/new/user/dl.png'),
        page: "agent",
      }, {
        label: "user.share",
        icon: require('../../assets/new/user/share.png'),
        page: "inviteFriends",
      }, {
        label: "user.selectLanguage",
        icon: require('../../assets/new/user/lan.png'),
        page: "setLan",
      }],
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.getUserInfo();
      }
    },
    showSign() {
      if (this.MemberInformation?.sign) {
        Dialog.alert({
          message: this.MemberInformation.sign,
          confirmButtonText: this.$t("My.Button.Two")
        })
      }
    },
    setting() {
      this.$router.push({ name: "Setting" });
    },
    handleClick(row) {
      if (!row.page) return;
      if (row.page == 'setLan') return this.$refs.setLanRef.open();
      if (row.page == 'service') return this.service();
      if (row.params) {
        this.$router.push({ name: row.page, params: row.params });
      } else {
        this.$router.push({ name: row.page });
      }
    },
    toRecharge() {
      // return this.service();
      this.$router.push({ name: "NewRecharge" });
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    service() {
      this.$store.dispatch("toService");
    },
    logoutEvent() {
      let _this = this;
      if (_this.MemberInformation.token) {
        Dialog.confirm({
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        }).then(() => {
          let pwdState = _this.$cookie.get("the-pwd-state");
          let pwdObj = _this.$cookie.get("the-pwd-obj");
          _this.$cookie.clear();
          _this.$cookie.set("the-pwd-state", pwdState);
          _this.$cookie.set("the-pwd-obj", pwdObj);
          _this.$router.push("/login");
          // window.location.reload()
        })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.MemberInformation = res
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    lanSubmit() {
      this.$store.dispatch('getGameList')
    }
  },
  created() {
    this.getUserInfo();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
  }
}
</script>
<style lang="less" scoped>
@Color: var(--theme);
@button-border-radius: 20px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: var(--bg1);

.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.wrap {
  min-height: calc(100vh - 50px);
  background-color: var(--light);

  .userInfo_bar_wrap {
    overflow: hidden;
    background-color: var(--bg);
    background: url('../../assets/new/user/header.png') no-repeat;
    background-size: 100% 100%;
    height: calc(192rem / 16);
    position: relative;
    .flex();
    align-items: flex-end;

    .left_icon {
      position: absolute;
      top: calc(24rem / 16);
      right: calc(8rem / 16);
      z-index: 1;

    }

    .user_contentLeft {
      flex: 1;
      .flex();
      margin-bottom: calc(10rem / 16);
      flex-direction: column;

      .contentLeft_img {
        width: calc(97rem / 16);
        height: calc(97rem / 16);
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.19);
        .flex();
      }

      .contentLeft_text {
        margin-top: calc(10rem / 16);

        ._id {
          font-size: calc(18rem / 16);
          color: var(--gray4)
        }

        .username {
          font-size: calc(20rem / 16);
          color: var(--gray4)
        }
      }
    }
  }

  .content {
    padding-bottom: calc(15rem / 16);

    // &_account {
    //   .flex(space-between);
    //   padding: calc(15rem / 16) calc(5rem / 16);
    //   border-radius: 5px;
    //   background-color: rgba(20, 35, 55, .4);

    //   .account_item {
    //     .common {
    //       height: calc(33rem / 16);
    //       display: flex;
    //       align-items: flex-start;
    //       justify-content: center;
    //       text-align: center;
    //       word-break: break-all;
    //       line-height: 1;
    //       font-size: calc(12rem / 16);
    //       color: #5d636e;
    //     }

    //     .label {
    //       .common();
    //       box-sizing: border-box;
    //       padding: 0 calc(5rem / 16)
    //     }

    //     .value {
    //       .common();
    //       height: calc(40rem / 16);
    //       align-items: center;
    //       font-size: calc(20rem / 16);
    //       color: var(--light);
    //       margin-top: calc(10rem / 16);
    //     }
    //   }
    // }
    &_btns {
      display: flex;

      .btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        .top {
          flex-grow: 1;
          color: var(--navColor);
          font-size: calc(14rem / 16);

          div {
            padding: calc(5rem / 16) 0;
          }
        }
      }

      .left {
        border-right: 1px solid #ededed;
        .top{
          div:nth-child(2) {
            text-align: center;
          }
        }
      }

      .btn_item {
        border-radius: calc(50rem / 16);
        color: var(--light);
        font-size: calc(14rem / 16);
        .flex();
        background: var(--btn);
        padding: calc(12rem / 16) calc(15rem / 16);

        img {
          width: calc(20rem / 16);
          display: block;
          padding-right: calc(15rem / 16);
        }
      }

      .withdraw {
        margin-left: calc(20rem / 16);
        .btn_item();
      }
    }

    &_list {
      margin-top: calc(18rem / 16);
      border-top: 1px solid #F4F4F4;
      display: flex;
      flex-wrap: wrap;

      .group {
        width: calc((100% - 2px) / 3);
        display: flex;
        height: calc(72rem / 16);
        align-items: center;
        justify-content: center;
        border-right: 1px solid #F4F4F4;
        border-bottom: 1px solid #F4F4F4;

        .left_wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .img {
            width: calc(24rem / 16);
            height: calc(24rem / 16);
            display: block;
          }

          .label {
            margin-top: calc(5rem / 16);
            color: var(--black);
            font-size: calc(12rem / 16);
          }
        }
      }

      .group:nth-child(3n) {
        border-right: none;
      }
    }

    .border {
      padding-top: 30px;
      border-top: 1px solid hsla(0, 0%, 59.2%, .1283);
    }

    .logout {
      margin: @wrapPadding;
      margin-top: calc(35rem / 16);
      border-radius: 6px;
      overflow: hidden;

      /deep/ .van-button {
        background: var(--btn) !important;
        border-color: transparent !important;
        color: var(--light) !important;
        font-size: calc(16rem / 16);
      }
    }
  }
}
</style>